import React from 'react';
import LeftColContent from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundSpecial from 'src/components/mobile/backgroundSpecial/backgroundSpecial';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => (
  <BlockContainer height="820px">
    <BackgroundSpecial responsive>
      <ChpaterTitle marginTop="26rem">{title}</ChpaterTitle>
      <LeftColContent body={query.mdx.body} containerStyle={{ height: '35%' }} />
    </BackgroundSpecial>
  </BlockContainer>
);


export default Slide;
