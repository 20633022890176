import styled from 'styled-components';

// added width 0 to animate border because it was leaving white mark without it
export const ImageWrapper = styled.div`
  position: relative;
  height: 300px;
  max-height: 80vh;
  overflow: hidden;
  border-top: 1px solid #cbcac8;
  border-bottom: 1px solid #cbcac8;
  margin: 15px 0;
  &:hover {
    cursor: move;
  }
`;

export const BackgroundImg = styled.img`
  transition: opacity 2s;
  margin-bottom: 2.6rem;
  max-width: unset;
`;
