import React, { useEffect } from 'react';
import LeftColContent from 'src/components/mobile/textAnimation/textAnimation';
import WarGlobalRangeAnimation from 'src/components/mobile/warGlobalRangeAnimation/WarGlobalRangeAnimation';
import { graphql, useStaticQuery } from 'gatsby';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => {
  useEffect(() => {

  }, []);

  const queryImages = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "chapter_1/sub_1/map-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, maxHeight: 593) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <BlockContainer className="slide3" style={{ background: '#071019' }}>
      <LeftColContent body={query.content.edges[0].node.body} />
      <WarGlobalRangeAnimation
        body={query.animation.edges[0].node.body}
        map={queryImages.map.childImageSharp.fluid}
      />
    </BlockContainer>
  );
};

export default Slide;
