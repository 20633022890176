import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Wrapper } from './styles';
import DraggableImage from '../draggableImage/DraggableImage';
import Legend from './Legend';

const WarGlobalRangeAnimation = ({ body, map }) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <Wrapper ref={ref}>
      <DraggableImage image={map.srcWebp} />
      <Legend body={body} visible={inView} />
    </Wrapper>
  );
};

export default WarGlobalRangeAnimation;
