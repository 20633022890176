import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: #071019;
  position: relative;
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  width: 390px;
  margin: 0 auto;
  max-width: 95%;

`;

export const LegendContainer = styled.div`
  flex: 1;
`;

export const LegendWrapper = styled.div`
  display: flex;
  margin-bottom: 0.68rem;
`;
export const LegendLabel = styled.div`
  font-family: "Red Hat Display";
  font-size: 0.625rem;
  color: #fff;
  letter-spacing: 0.025rem;
  opacity: 0;
  transition: all 2s 1s;
  ${({ visible }) => visible
    && css`
      opacity: 1;
      width: 100%;
    `};
`;

export const LabelColor = styled.div`
  width: 39px;
  height: 13px;
  margin-right: 1rem;
      margin-top: 2px;
`;

export const LabelShape = styled.div`
  width: 0;
  height: 100%;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.82, 0, 0.195, 1) 1s,
    width 1.7s cubic-bezier(0.82, 0, 0.195, 1) 1s;
  ${({ visible }) => visible
    && css`
      opacity: 1;
      width: 100%;
    `};
`;

export const LabelCentral = styled(LabelShape)`
  background: #d6ab48;
`;
export const LabelGerman = styled(LabelShape)`
  background: #ecdbb6;
`;
export const LabelNeutral = styled(LabelShape)`
  background: #fff;
`;
export const LabelEntenty = styled(LabelShape)`
  background: #777772;
`;
export const LabelEntentyAllies = styled(LabelShape)`
  background: #cbcac8;
`;
export const LabelDominia = styled(LabelShape)`
  background: #e2efe6;
`;
