import React from 'react';
import Quote from 'src/components/mobile/quote/quote';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => {
  const {
    author,
    authorInfo,
  } = query.allMdx.edges[0].node.frontmatter;
  const { body } = query.allMdx.edges[0].node;

  return (
    <BlockContainer height="450px">
      <BackgroundPhoto fixed={query.file.childImageSharp.fixed} enter alt="slide-photo">
        <Quote
          fontColor="white"
          quoteText={body}
          quoteAuthor={author}
          quoteAuthorRole={authorInfo}
          isDimmed
        />
      </BackgroundPhoto>
    </BlockContainer>
  );
};

export default Slide;
