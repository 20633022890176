import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import {
  Legend,
  LegendContainer,
  LegendWrapper,
  LegendLabel,
  LabelColor,
  LabelCentral,
  LabelGerman,
  LabelNeutral,
  LabelEntenty,
  LabelEntentyAllies,
  LabelDominia,
} from './styles';

const AnimationLegend = ({ body, visible }) => (
  <Legend>
    <MDXProvider
      components={{
        LegendContainer,
        LegendWrapper,
        LegendLabel,
        LabelColor,
        LabelCentral,
        LabelGerman,
        LabelNeutral,
        LabelEntenty,
        LabelEntentyAllies,
        LabelDominia,
        p: props => <p {...props} tabIndex="0" />
      }}
    >
      <MDXRenderer visible={visible}>{body}</MDXRenderer>
    </MDXProvider>
  </Legend >
);

export default AnimationLegend;
