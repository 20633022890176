import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from 'react-icons/md';

import {
  BackgroundImg,
  ImageWrapper,
} from './styles';

const ICON_BASIC = {
  position: 'absolute',
  color: '#fff',
  fontSize: 40,
};

const WarGlobalRangeAnimation = ({ image, zoomEnabled = false }) => {
  const options = {
    limitToBounds: false,
    centerContent: true
  };
  const pinch = { disabled: !zoomEnabled };
  const zoomInProp = { disabled: !zoomEnabled };
  const zoomOutProp = { disabled: !zoomEnabled };
  const doubleClick = { disabled: !zoomEnabled };

  return (
    <ImageWrapper>
      <TransformWrapper
        options={options}
        pinch={pinch}
        zoomIn={zoomInProp}
        zoomOut={zoomOutProp}
        doubleClick={doubleClick}
        defaultScale={1}
        defaultPositionX={-350}
        defaultPositionY={-150}
      // style={{ height: 300 }}
      >
        <TransformComponent
        // style={{ height: 300 }}
        >
          <BackgroundImg src={image} alt="map" />
        </TransformComponent>
      </TransformWrapper>
      <MdKeyboardArrowLeft
        style={{
          ...ICON_BASIC,
          top: '50%',
          left: 5,
          transform: 'translateY(-50%)',
        }}
      />
      <MdKeyboardArrowRight
        style={{
          ...ICON_BASIC,
          top: '50%',
          right: 5,
          transform: 'translateY(-50%)',
        }}
      />
      <MdKeyboardArrowUp
        style={{
          ...ICON_BASIC,
          top: 5,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <MdKeyboardArrowDown
        style={{
          ...ICON_BASIC,
          bottom: 5,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </ImageWrapper>
  );
};

export default WarGlobalRangeAnimation;
