import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_1/sub_1/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      content: allMdx(
        filter: {
          frontmatter: {
            language: { eq: "RU" }
            title: { eq: "slide-1-1-5-1-mobile" }
          }
        }
      ) {
        edges {
          node {
            body
            frontmatter {
              title
            }
          }
        }
      }
      animation: allMdx(
        filter: {
          frontmatter: {
            language: { eq: "RU" }
            title: { eq: "slide-1-1-5-2" }
          }
        }
      ) {
        edges {
          node {
            body
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
