import React from 'react';
import SignedPhoto from 'src/components/mobile/signedPhoto/signedPhoto.jsx';


const Slide = ({ query }) => (
  <>
    <SignedPhoto
      photo={query.file.childImageSharp.fixed}
      text={query.mdx.body}
      colorText="#fff"
      alt="slide-photo"
    />
  </>
);

export default Slide;
