import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_1/sub_1/subSlide5';

const Slide = () => {
  // const query = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "ententa-soldiers.png"}) {
  //       childImageSharp {
  //         fixed(width: 1484, height: 1080)  {
  //           ...GatsbyImageSharpFixed_withWebp_noBase64
  //           width
  //         }
  //       }
  //     }
  //   }
  // `);
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: { language: { eq: "RU" }, title: { eq: "slide-1-1-4" } }
      ) {
        body
      }
      file(relativePath: { eq: "chapter_1/sub_1/4.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);

  return (
    <Template query={query} />
  );
};

export default Slide;
