import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useInView } from 'react-intersection-observer';
import {
  WrapperBackground, Wrapper, WrapperText, ObserverRef,
} from './styles';

const SignedPhoto = ({
  photo,
  text,
  colorText = '#ffffff',
  alt,
  fullScreen = false,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.01,
  });
  return (
    <>
      <Wrapper ref={ref}>
        <WrapperBackground fixed={photo} alt={alt} />
        <WrapperText
          colorText={colorText}
          visible={inView}
          fullScreen={fullScreen}
        >
          <MDXRenderer>{text}</MDXRenderer>
        </WrapperText>
      </Wrapper>
    </>
  );
};

export default SignedPhoto;
