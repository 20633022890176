import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_1/sub_1/slide6';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: { language: { eq: "RU" }, title: { eq: "slide-1-1-6" } }
      ) {
        body
        frontmatter {
          alt
        }
      }
    }
  `);
  return (
    <Template query={query} />
  );
};

export default Slide;
