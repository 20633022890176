import styled, { keyframes, css } from 'styled-components';
import Img from 'gatsby-image';
import { fonts } from '../../../../../globals';

const enter = keyframes`
    0%{
        transform: translate3d(0,40%,0);
        opacity: 0;
    }
    100%{
        transform: translate3d(0,0,0);
        opacity: 1;
    }
`;

const exit = keyframes`
    0%{
        transform: translate3d(0,0,0);
        opacity: 1;
    }
    100%{
        transform: translate3d(0,40%,0);
        opacity: 0;
    }
`;

const enterAnimation = ({ visible }) => {
  if (visible) {
    return css`
      animation: ${enter} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
    `;
  }
  return css`
    animation: ${exit} 0.8s cubic-bezier(0.78, 0, 0.235, 1) forwards;
  `;
};

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1.5rem;
`;

export const WrapperBackground = styled(Img)`
  max-width: 80%;
`;

export const WrapperText = styled.div`
  ${{ ...fonts.popup }}
  font-weight: 400;
  text-align: center;
  padding: 1rem 1.75rem;
  color: ${({ colorText }) => colorText};
  width: ${({ widthText }) => `${widthText}%`};
  opacity: 0;
  ${enterAnimation};
  margin-left: ${({ fullScreen }) => fullScreen && '6.6%'};
`;
